<template>

  <div class="details">
<!--    <div class="nav-bar">-->
<!--&lt;!&ndash;      <img src="../assets/images/back.png" alt="" @click="jumpUrl('/home')"/>&ndash;&gt;-->
<!--      <span>余额(USDT)</span>-->
<!--    </div>-->
    <div class="balance">
      <p>{{$t('home.ye')}}（USDT）</p>
      <div class="details" @click="getWithdrawalInfo">{{$t('home.tb')}}</div>
      <div class="balance-number"><span>{{ getReleaseInfoObg.balance }}</span></div>
      <div class="asset">
        <div class="item">
          <div class="names">{{$t('home.t1')}}(USDT)</div>
          <div class="money">{{ getReleaseInfoObg.toBeRelease }}</div>
        </div>
        <div class="item">
          <div class="names">{{$t('home.t2')}}</div>
          <div class="money">{{ getReleaseInfoObg.base }}</div>
        </div>
      </div>
    </div>
    <div class="tableHeader">
      <div style="text-align: left">{{$t('home.t3')}}</div>
      <div>{{$t('home.t4')}}</div>
      <div style="text-align: right">{{$t('home.t5')}}</div>
    </div>
    <van-list
        v-if="list.length>0"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('home.noMore')"
        :loading-text="$t('home.loading')"
        :error-text="$t('home.failed')"
        @load="onLoad"
    >

      <div v-for="(item,i) in list" :key="i" class="list_info">
        <div class="left">
          <strong v-if="item.amount<0" style="color: #FF5A5A">{{item.amount}}</strong>
          <strong v-else >{{'+'+item.amount}}</strong>
        </div>
        <div class="center">{{$t('home.t6')}}</div>
        <div class="right">
          {{locale==='en-US'?toHHmmss(item.createTime,-5):toHHmmss(item.createTime,+8)}}
          <div class="ye">{{item.afterAmount}}</div>
        </div>
      </div>
    </van-list>
    <div class="no-data" v-else>
      <img src="../assets/images/no_data.png" alt=""/>
    </div>
    <!--  提币-->
    <van-popup v-model:show="showWithdrawal"  closeable :close-on-click-overlay="false"  @close="close">
      <div class="transaction">
        <div class="title">{{ $t('home.tb') }}</div>
        <van-cell-group inset>
          <van-field v-model="withdrawal"  center :placeholder="$t('home.tbp')" @blur="changeWithdrawal(withdrawal,'withdrawal')"/>
        </van-cell-group>
        <div class="num flex-between">
          STT/USDT：
          <strong>{{price}}</strong>
        </div>
        <div class="num flex-between">
          {{ $t('home.khdstt') }}：
          <strong style="color: #AF65EE">{{getSTT}}</strong>
        </div>
        <div class="confirm-btn">
          <van-button class="confirm" @click="getWithdrawal">{{ $t('home.qrtq') }}</van-button>
        </div>
        <div class="tips">
          {{ $t('home.tbtips') }}
        </div>
      </div>
    </van-popup>
    <van-popup v-model:show="show" closeable position="center" :close-on-click-overlay="false" @close="close">
      <div class="popup-reservation">
        <div class="title">{{ $t('home.mm') }}</div>
        <van-cell-group inset>
          <van-field center :placeholder="$t('home.srmm')" v-model="password" maxlength="6" type="password"/>
        </van-cell-group>
        <div class="confirm-btn">
          <van-button class="confirm" @click="submitWithdrawal">{{ $t('home.qd') }}</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script setup>
import {onBeforeMount, ref,getCurrentInstance} from 'vue'
import {getSttUsdtPrice, getUserAssetsDetail, setWithdraw,getReleaseInfo} from "../api";
import {div, get2String, getPublicKey, toHHmmss} from '../utils/decimalTool'
import {useI18n} from "vue-i18n";
import {Toast} from "vant";
const { $t } = getCurrentInstance().proxy;
// eslint-disable-next-line no-unused-vars
const {locale } = useI18n()
// const value=ref(0)
const withdrawal=ref('')
const showWithdrawal=ref(false)
const getSTT=ref(0)
const price=ref(0)
const recharge=ref('')
// 密码
const show=ref(false)
const password=ref('')
// const columns =ref([
//   { text: $t('home.qb'), value: 0 },
//   { text: $t('home.lxjl'), value: 1 },
//   { text: $t('home.dthl'), value: 2 },
//   { text: $t('home.vipjl'), value: 3 },
//   { text: $t('home.yy'), value: 4 },
//   { text: $t('home.cz'), value: 5 },
//   { text: $t('home.tx'), value: 6 },
//   { text: $t('home.txth'), value: 7 },
//   { text: $t('home.thbj'), value: 8 },
//   { text: $t('home.jslx'), value: 9 },
//   { text: $t('home.jsbj'), value: 10 },
//   // { text: $t('home.jjff'), value: 11 },
//   { text: $t('home.sysljl'), value: 12 },
//   // { text: $t('home.pjjl'), value: 13 },
//   { text: $t('home.dr')+' USDT', value: 18 },
// ])


// const columnObj=ref((function() {
//   let obj = {};
//   columns.value.forEach(v=>{
//     obj[v["value"]] = v;
//   })
//   return obj;
// })());

const pageNo=ref(1)
const total=ref(0)
const list = ref([]);
const getReleaseInfoObg = ref({});
const loading = ref(false);
const finished = ref(false);
const getReleaseInfoFun =()=>{
  getReleaseInfo().then(res=>{
    getReleaseInfoObg.value = res.data
  })
}
const getWithdrawalInfo=()=>{
  // withdrawal.value=0
  getSttUsdtPrice().then(res=>{
    price.value=res.data.price
    // getSTT.value=div(withdrawal.value,res.data.price)
    showWithdrawal.value=true

  })
}
const changeWithdrawal=(val,name)=>{

  if(name==='withdrawal'){
    withdrawal.value = get2number(val)
  }else{
    recharge.value =get2number(val)
  }

  let num=div(get2number(val),price.value)
  getSTT.value=get2String(num)
}
const  getWithdrawal=()=>{
  if(isNaN(withdrawal.value) ){
    Toast($t('home.qsrje'))
    return
  }
  if(getSTT.value==0 ){
    Toast($t('home.tb')+$t('home.getSTT'))
    return
  }
  show.value=true
}
const close=()=>{
  password.value=''
  withdrawal.value=''
  recharge.value=''
  getSTT.value=0
}
const submitWithdrawal=()=>{
  setWithdraw({
    "amount":withdrawal.value,   //提现金额
    "payPwd":getPublicKey(password.value),        //支付密码
    "address":sessionStorage.getItem('address')   //提现地址

  }).then(res=>{
    if(res.code==='200'){
      withdrawal.value=0
      password.value=''
      Toast($t('home.success'))
      show.value=false
      showWithdrawal.value=false
      getHttp()
      getReleaseInfoFun()
    }else{
      Toast(res.msg)
    }
  })
}
const get2number=(val)=>{
  let reg = /^\d+(.\d{1,2})?$/g;
  let number
  if (reg.test(val)) { //正则匹配通过，提取有效文本
    number = val;
  } else { //正则匹配不通过，直接清空
    number = '';
    Toast($t('home.get2number'))
  }
  return number

}
const getHttp=()=>{
  getUserAssetsDetail({
    // "styleType": 1,    //1：所有钱包明细 2：“充值余额”字段明细
    "type":19, //记录类型，全部：0，其他按钱包记录变动类型依次传入
    "pageNo":pageNo.value,
    "pageSize":10,
  }).then(res=>{
    list.value.push(...res.data.logs)
    total.value=res.data.page.totalNum
  })
}
const onLoad = () => {
  // 异步更新数据
  // setTimeout 仅做示例，真实场景中一般为 ajax 请求
  setTimeout(() => {

    // 数据全部加载完成
    if (list.value.length >= total.value) {
      finished.value = true;
    }else {
      pageNo.value+=1
      getHttp()
      loading.value = false;

    }
  }, 1000);
};
onBeforeMount(()=>{
  getHttp()
  getReleaseInfoFun()
})
</script>
<style scoped lang="less">
.details{
  padding: 0px 18px 18px 18px;
  background-image: url("~@/assets/images/bg_pic.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  min-height: 100vh;
  box-sizing: border-box;
  .asset{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item{
      .names{
        font-weight: 500;
        font-size: 16px;
        color: #7E8D95;
      }
      .money{
        margin: 10px 0;
        font-weight: bold;
        font-size: 26px;
        color: #FFFFFF;
      }
    }
  }
  .balance{
    background: #000000;
    border-radius: 46px;
    margin:0px 0px 15px 0px;
    padding: 30px 10px 15px 10px;
    position: relative;
    .details{
      font-weight: 500;
      font-size: 15px;
      color: #6FFEE8;
      position: absolute;
      top:31px;
      right: 22px;
    }
    p{
      font-weight: 500;
      font-size: 16px;
      color: #7E8D95;
      margin-bottom: 15px;
      margin-top: 0;
    }
    .balance-number{
      font-weight: bold;
      font-size: 23px;
      color: #7E8D95;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;

      span{
        font-weight: bold;
        font-size: 35px;
        color: #FFFFFF;
        margin-left: 7px;
      }
    }
    .withdrawal{
      width: 140px;
      height: 50px;
      background: #7E8D95;
      border-radius: 28px;
      font-weight: bold;
      font-size: 17px;
      color: #FFFFFF;
      border: none;
    }
    .recharge{
      width: 140px;
      height: 50px;
      background: linear-gradient(90deg, #57F6CD, #AF65EE);
      box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
      border-radius: 28px;
      font-weight: bold;
      font-size: 17px;
      color: #000000;
      border: none;

    }
  }
  .nav-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 52px;
    img{
      width: 16px;
      height: 15px;
      padding-right: 34px;
    }
    span{
      font-weight: bold;
      font-size: 17px;
      color: #FFFFFF;
    }
    .zk{
      font-weight: 500;
      font-size: 15px;
      color: #6FFEE8;
      display: flex;
      align-items: center;
      img{
        width: 11px;
        height: 6px;
        margin-left: 6px;
        padding: 0;
      }
    }
    /deep/.van-popup {
      background: #232A2E;
    }
    /deep/.van-picker{
      background: #232A2E;
    }
    /deep/.van-picker-column__item{
      color: #FFFFFF;
    }
    /deep/.van-picker__confirm{
      color: #6FFEE8;
    }

    /deep/.van-picker__mask{
      background: none;
    }
  }
  .tableHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 0 19px 0;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    border-bottom: 1px solid #7E8D95;
    &>div{
      width: 33%;
    }
  }
  .list_info{
    border-bottom: 1px solid #7E8D95;
    padding: 19px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      width: 33%;
      font-weight: 500;
      font-size: 12px;
      color: #FFFFFF;
      text-align: left;

      p{
        font-weight: 500;
        font-size: 17px;
        color: #FFFFFF;
        margin: 0 0 10px 0;
        text-align: left;
      }
      strong{
        font-weight: bold;
        font-size: 20px;
        color: #6FFEE8;
        //margin-top: 10px;
      }
    }
    .center{
      width: 33%;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
    }
    .right{
      width: 33%;
      display: flex;
      align-items: end;
      flex-direction: column;
      font-weight: 500;
      font-size: 12px;
      color: #7E8D95;
      .ye{
        font-weight: 500;
        font-size: 12px;
        margin-top: 10px;
        color: #FFFFFF;
      }

    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
  /deep/ .van-popup{
    background: transparent;
  }
  /deep/.van-popup__close-icon--top-right{
    top:30px;
    height: 24px;
  }
  .transaction{
    box-sizing: border-box;
    width: 312px;
    //height: 320px;
    background: #232A2E;
    border-radius: 25px;
    padding: 28px 19px 31px 19px;
    .title{
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      margin-bottom: 30px;
    }
    .num{
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      width: 90%;
      margin: 15px auto 0;
      strong{
        font-weight: bold;
        font-size: 14px;
        color: #6FFEE8;
      }
    }
    .tips{
      font-weight: 500;
      font-size: 12px;
      color: #7E8D95;
      margin-top: 17px;
    }
    .confirm-btn{
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      .confirm{
        width: 187px;
        height: 56px;
        background: linear-gradient(90deg, #57F6CD, #AF65EE);
        box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
        border-radius: 28px;
        font-weight: bold;
        font-size: 17px;
        color: #000000;
        text-align: center;
        border: none;
      }
    }
    /deep/ .van-cell-group{
      width: 100%;
      height: 62px;
      background: #000000;
      border-radius: 31px;
    }
    /deep/ .van-cell-group--inset{
      margin: 0;
      .van-cell{
        font-weight: 500;
        font-size: 17px;
        color: #7E8D95;
        width: 264px;
        height: 62px;
        background: #000000;
        border-radius: 31px;
      }
      .van-field__control{
        text-align: center;
        font-weight: bold;
        font-size: 21px;
        color: #FFFFFF;
      }
    }
  }
  .popup-reservation{
    box-sizing: border-box;
    width: 312px;
    height: 260px;
    background: #232A2E;
    border-radius: 25px;
    padding: 28px 19px 0 19px;
    .title{
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      margin-bottom: 30px;
    }
    .confirm-btn{
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      .confirm{
        width: 187px;
        height: 56px;
        background: linear-gradient(90deg, #57F6CD, #AF65EE);
        box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
        border-radius: 28px;
        font-weight: bold;
        font-size: 17px;
        color: #000000;
        text-align: center;
        line-height: 56px;
        border: none;
      }
    }
    /deep/ .van-cell-group{
      width: 264px;
      height: 62px;
      background: #000000;
      border-radius: 31px;
    }
    /deep/ .van-cell-group--inset{
      margin: 0;
      .van-cell{
        font-weight: 500;
        font-size: 17px;
        color: #7E8D95;
        width: 264px;
        height: 62px;
        background: #000000;
        border-radius: 31px;
      }
      .van-field__control{
        text-align: center;
        font-weight: bold;
        font-size: 21px;
        color: #FFFFFF;
      }
    }
  }
  /deep/.van-field__control::placeholder{
    font-weight: 500;
    font-size: 17px;
    color: #7E8D95;
  }
}
.no-data{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  img{
    width: 176px;
    height: 258px;
  }
}
</style>
